// src/modules/common/GlobalLoader.js

import React, {useContext} from 'react';
import {LoaderContext} from '../../contexts/LoaderContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const GlobalLoader = (props) => {
	const [isLoading, ] = useContext(LoaderContext);

	if(isLoading) {
		return (
			<div id="global-loader">
				<FontAwesomeIcon icon={faSpinner} size="4x" spin />
			</div>
		);
	} else {
		return null;
	}
}

export default GlobalLoader;