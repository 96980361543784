// src/index.js

import React from 'react';
import ReactDOM from 'react-dom';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import App from './App';
import * as serviceWorker from './serviceWorker';

// WebfontLoader config object
const wflConfig = {
	google: {
		families: ['Source Sans Pro:400,600, 900']
	}
}

ReactDOM.render(
	<WebfontLoader config={wflConfig}>
		<App />
	</WebfontLoader>,
  	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
