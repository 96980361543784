// src/App.js

import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {EndpointsProvider} from './contexts/EndpointsContext';
import {LoaderProvider} from './contexts/LoaderContext';
import {OverlayProvider} from './contexts/OverlayContext';
import SimpleReactLightbox from 'simple-react-lightbox';
import GlobalLoader from './modules/common/GlobalLoader';
import LeftColumn from './modules/LeftColumn';
import HeaderMobile from './modules/mobiles/HeaderMobile';
import Home from './modules/Home';
import PostsList from './modules/PostsList';
import Single from './modules/Single';
import Contact from './modules/Contact';
import Footer from './modules/Footer';
import Overlay from './modules/Overlay';

import './css/bootstrap.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css'
import './css/styles.css';

const App = () => {
  	return (
	  	<BrowserRouter>
		  	<EndpointsProvider>
			  	<LoaderProvider>
					<OverlayProvider>						
						  	
						<HeaderMobile />
						<LeftColumn />
						
						<Switch>
							<Route exact path="/">
								<p>coucou</p>
								<Home />
							</Route>
							<Route exact path="/cat/:catname">
								<PostsList />
							</Route>
							<Route exact path="/cat/:catname/:id">
								<SimpleReactLightbox>
									<Single />
								</SimpleReactLightbox>
							</Route>
							<Route exact path="/contact">
								<Contact />
							</Route>
						</Switch>

						<Footer />

						<Overlay />	
						<GlobalLoader />
					</OverlayProvider>
				</LoaderProvider>
			</EndpointsProvider>
		</BrowserRouter>
  	);
}

export default App;