// src/modules/Single.js

import React, {useContext, useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {LoaderContext} from '../contexts/LoaderContext';
import {EndpointsContext} from '../contexts/EndpointsContext';
import { SRLWrapper } from 'simple-react-lightbox';
import Player from './Player';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearchPlus} from '@fortawesome/free-solid-svg-icons';

import Masonry from 'react-masonry-css';

const breakpointColumnsObj = {
    default: 4,
    1400: 4,
    1272: 3,
    1048: 2
};

const SRLOptions = {
	settings: {
		hideControlsAfter: false
	},
	buttons: {
		showAutoplayButton: false,
		showDownloadButton: false,
		showFullscreenButton: false
	},
	caption: {
		showCaption: false
	},
	thumbnails: {
		showThumbnails: false
	}
};

const Single = (props) => {
	// use LoaderContext, setter only
	const [, setIsLoading] = useContext(LoaderContext);
	// use EndpointsContect
	const endpoints = useContext(EndpointsContext);
	const [error, setError] = useState(false);
	const [oeuvre, setOeuvre] = useState([]);

	const cat = props.location.pathname.match(/^.*\/(.*)\/.*\/$/);
	console.log(cat);
	const id = props.location.pathname.match(/\/([^\/]+)\/?$/)[1];
	const wpRestUrl = endpoints.config.baseUrl+endpoints.categories['single'].wpRestUrl+id;

	useEffect(() => {
		fetch(wpRestUrl)
		.then((response) => {
    		if(!response.ok) throw new Error(response.status);
    		else return response.json();
  		})
  		.then((result) => {
    		setIsLoading(false);
			setOeuvre(result);
  		})
  		.catch((error) => {
			setIsLoading(false);
			setError(error);
  		});

		return () => {
			setOeuvre([]);
			setIsLoading(true);
			setError(null);
		}    	
  	}, [wpRestUrl, setIsLoading])

	if (error) { // handle rest error
    	return (
			<main id="main" className="col-lg-9 col-md-9">
				<div>Erreur : {error.message}</div>
			</main>
		);
  	} else { // display
		return (
			<main id="main" className="col-lg-9 col-md-9 single">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div id="section-header">
								<h2>{oeuvre.titre}</h2>
								<hr />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							{oeuvre.diaporama &&
								<div className="diaporama">
									<SRLWrapper options={SRLOptions}>
										<Masonry
											breakpointCols={breakpointColumnsObj}
											className="ms-grid"
											columnClassName="ms-grid-column">										
											{oeuvre.diaporama.map(image => (
												<div key={image.id}>
													<a href={image.srcLarge} className="oeuvre-diapo" title="Agrandir" data-attribute="SRL">
														<img src={image.srcMin} className="img-fluid" alt={oeuvre.titre} />
														<div className="post-title">
															<h3>
																<FontAwesomeIcon icon={faSearchPlus} />
															</h3>
														</div>
													</a>												
												</div>
											))}										
										</Masonry>
									</SRLWrapper>
									<hr />
								</div>
							}

							{oeuvre.video &&
								<div className="video">
									<Player source={oeuvre.video} largeur="100%" hauteur="310" title={oeuvre.titre} />
								</div>
							}
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<div className="presentation" dangerouslySetInnerHTML={{__html: oeuvre.presentation}}></div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default withRouter(Single);