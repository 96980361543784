// src/modules/mobiles/HeaderMobile.js

import React from 'react';
import ShowMobileMenu from './ShowMobileMenu';
import Logo from '../common/Logo';
import MainTitle from '../common/MainTitle';

const Header = () => {
	return (
		<div id="header-mobile" className="d-xl-none d-lg-none d-md-none d-sm-block d-xs-block">
			<div className="position-relative">
				<Logo />
				<MainTitle />
				<ShowMobileMenu />
			</div>			
		</div>
	);
}

export default Header;