// src/modules/Nav.js

import React from 'react';
import {NavLink} from 'react-router-dom';

const Nav = () => {
	return (
		<nav id="nav">
			<ul>
				<li>
					<NavLink exact to="/" activeClassName="active">Accueil</NavLink>
				</li>
				<li>
					<NavLink to="/cat/sculptures" activeClassName="active">Sculptures</NavLink>
				</li>
				<li>
					<NavLink to="/cat/gravures" activeClassName="active">Gravures</NavLink>
				</li>
				<li>
					<NavLink to="/cat/hauts_reliefs" activeClassName="active">Hauts-reliefs</NavLink>
				</li>
				<li>
					<NavLink to="/cat/bas_reliefs" activeClassName="active">Bas-reliefs</NavLink>
				</li>
				<li>
					<NavLink to="/contact" activeClassName="active">Contact</NavLink>
				</li>
			</ul>
		</nav>
	);
}

export default Nav;