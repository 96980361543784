// src/modules/PostsList.js

import React, {useContext, useState, useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {LoaderContext} from '../contexts/LoaderContext';
import {EndpointsContext} from '../contexts/EndpointsContext';
import Masonry from 'react-masonry-css';

const breakpointColumnsObj = {
    default: 4,
    1400: 4,
    1272: 3,
    1048: 2
};

const PostsList = (props) => {
	// use Loadercontext, setter only
	const [, setIsLoading] = useContext(LoaderContext);
	// use EndpointsContext
	const endpoints = useContext(EndpointsContext);
	const [error, setError] = useState(false);
	const [oeuvres, setOeuvres] = useState([]);
	console.log(props.location);
	const chemin = props.location.pathname.match(/\/([^\/]+)\/?$/)[1];
	const wpRestUrl = endpoints.config.baseUrl+endpoints.categories[`${chemin}`].wpRestUrl;
	const title = endpoints.categories[`${chemin}`].title;

  	useEffect(() => {
		fetch(wpRestUrl)
		.then((response) => {
    		if(!response.ok) throw new Error(response.status);
    		else return response.json();
  		})
  		.then((result) => {
    		setIsLoading(false);
			setOeuvres(result);
  		})
  		.catch((error) => {
			setIsLoading(false);
			setError(error);
  		});

		return () => {
			setOeuvres([]);
			setIsLoading(true);
			setError(null);
		}
    	
  	}, [wpRestUrl, setIsLoading])

	if (error) { // handle rest error
    	return (
			<main id="main" className="col-lg-9 col-md-9">
				<div>Erreur : {error.message}</div>
			</main>
		);
  	} else { // display
		return (
			<main id="main" className="col-lg-9 col-md-9">
				<div className="container-fluid">
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div id="section-header">
								<h2>{title}</h2>
								<hr />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className="ms-grid"
								columnClassName="ms-grid-column">
								{oeuvres.map(oeuvre => (
									<div key={oeuvre.id}>
										<Link to={`${chemin}/${oeuvre.id}`} className="oeuvre">
											<img src={oeuvre.photo.url} className="img-fluid" alt={oeuvre.titre} />
											<div className="post-title">
												<h3>{oeuvre.titre}</h3>
											</div>
										</Link>
									</div>
								))}
							</Masonry>
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default withRouter(PostsList);