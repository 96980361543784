// src/modules/Home.js

import React from 'react';
import Actualites from './Actualites';
import Presentation from './Presentation';
import Services from './Services';
import Carte from './Carte';

const Home = () => {
	return (
		<main id="main" className="col-lg-9 col-md-9">
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-8 col-lg-8">
						<Actualites />
					</div>
					<div className="col-xl-4 col-lg-4">
						<Presentation />
					</div>				
					<hr />
				</div>
				<div className="row">
					<div className="col-xl-6 col-lg-6">
						<Services />
					</div>					
					<div className="col-xl-6 col-lg-6">
						<Carte />	
					</div>
				</div>
			</div>			
		</main>
	)
}

export default Home;