// src/modules/SocialLinks.js

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';

const fbTooltip = (
	<Tooltip id="fbTooltip">
		<span>Suivez-moi sur Facebook !</span>
	</Tooltip>
);

const instaTooltip = (
	<Tooltip id="instaTooltip">
		<span>Suivez-moi sur Instagram !</span>
	</Tooltip>
);

const youtubeTooltip = (
	<Tooltip id="youtubeTooltip">
		<span>Regardez ma chaîne Youtube !</span>
	</Tooltip>
);

const SocialLinks = () => {
	return (
		<div id="social-links">
			<OverlayTrigger placement="top" overlay={fbTooltip}>
				<a href="https://www.facebook.com/yann.yannchan.54" className="social-link" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon icon={faFacebookF} />
				</a>
			</OverlayTrigger>

			<OverlayTrigger placement="top" overlay={youtubeTooltip}>
				<a href="https://youtube.com/yannchancerel" className="social-link" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon icon={faYoutube} />
				</a>
			</OverlayTrigger>
		</div>
	);
}

export default SocialLinks;