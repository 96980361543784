// src/modules/LeftColumn.js

import React from 'react';
import Logo from './common/Logo';
import MainTitle from './common/MainTitle';
import Nav from './Nav';
import SocialLinks from './SocialLinks';

const LeftColumn = () => {
	return (
		<div id="left-column" className="col-lg-3 col-md-3 d-none d-md-block d-lg-block d-xl-block">
			<div className="position-realitive">
				<header id="header" role="banner">
					<Logo />
					<MainTitle />
				</header>
				<hr />
				<Nav />
				<SocialLinks />
			</div>
		</div>
	);
}

export default LeftColumn;