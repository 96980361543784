// src/modules/Overlay.js

import React, {useContext, lazy, Suspense} from 'react';
import {OverlayContext} from '../contexts/OverlayContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

const Overlay = () => {
	// use OverlayContext
	const [ctxOverlay, setCtxOverlay] = useContext(OverlayContext);
	let importPath = ctxOverlay.from+ctxOverlay.whatToShow;
	// fucking ''+ before mandatory
	const Component = lazy(() => import(''+importPath));

	const handleClick = evt => {
		setCtxOverlay(ctx => ({...ctxOverlay, overlayOpen: !ctxOverlay.overlayOpen}));
		console.dir(ctxOverlay);
	}

	return (
		ctxOverlay.overlayOpen ?
			<div id="overlay">
				<div className="position-relative">
					<button id="overlay-close" onClick={handleClick}>
						<FontAwesomeIcon icon={faTimes} />
					</button>
					<Suspense fallback={<div>Loading...</div>}>
						<Component />
					</Suspense>
				</div>				
			</div>
		:
			null
	);
}

export default Overlay;