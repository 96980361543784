// src/contexts/EndpointsContext.js

// on importe createContext qui servira à la création d'un ou plusieurs contextes
import React, { createContext } from 'react';

/**
 * `createContext` contient 2 propriétés :
 * `Provider` et `Consumer`. Nous les rendons accessibles
 * via la constante `EndpointsContext` et on initialise une
 * propriété par défaut "name" qui sera une chaîne vide.
 * On exporte ce contexte afin qu'il soit exploitable par
 * d'autres composants par la suite via le `Consumer`
 */
const EndpointsContext = createContext({
	urls: {}
});

/**
 * la classe EndpointsProvider fera office de... Provider (!)
 * en englobant son enfant direct
 * dans le composant éponyme. De cette façon, ses values
 * seront accessibles de manière globale via le `Consumer`
 */
class EndpointsProvider extends React.Component {
    state = {
		config: {
			baseUrl: 'https://www.sculpture-gravure-granit-yannchancerel.com/wp-backend/wp-json/yann/v1'
			// baseUrl: 'http://localhost/yannwp/wp-json/yann/v1'
		},
		categories: {
			actualites: {
				title: 'Actualités',
				wpRestUrl: '/actualites'
			},
			presentation: {
				title: 'Présentation',
				wpRestUrl: '/presentation'
			},
			services: {
				title: 'Services',
				wpRestUrl: '/services'
			},
			sculptures: {
				title: 'Sculptures',
				wpRestUrl: '/oeuvres/sculpture?per_page=100&page=1'
			},
			gravures: {
				title: 'Gravures',
				wpRestUrl: '/oeuvres/gravure?per_page=100&page=1'
			},
			hauts_reliefs: {
				title: 'Hauts-reliefs',
				wpRestUrl: '/oeuvres/haut_relief?per_page=100&page=1'
			},
			bas_reliefs: {
				title: 'Bas-reliefs',
				wpRestUrl: '/oeuvres/bas_relief?per_page=100&page=1'
			},
			single: {
				wpRestUrl: '/oeuvre/'
			}
		}
    };

    render() {
        return (
			/**
			* la propriété value est très importante ici, elle rend
			* le contenu du state disponible aux `Consumers` de l'application
			*/
			<EndpointsContext.Provider value={this.state}>
				{this.props.children}
			</EndpointsContext.Provider>
        );
    }
}

export {EndpointsContext, EndpointsProvider};