// src/modules/Actualites.js

import React, {useContext, useState, useEffect} from 'react';
import {EndpointsContext} from '../contexts/EndpointsContext';
import RBCarousel from 'react-bootstrap-carousel';
import {LoaderContext} from '../contexts/LoaderContext'
import Actualite from './Actualite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';

const leftArrow = <FontAwesomeIcon icon={faAngleLeft} size="3x" fixedWidth />
const rightArrow = <FontAwesomeIcon icon={faAngleRight} size="3x" fixedWidth />

const Actualites = () => {
	// use LoaderContext, setter only
	const [, setIsLoading] = useContext(LoaderContext);
	// use EndpointsContect
	const endpoints = useContext(EndpointsContext);
	const [error, setError] = useState(false);
	const [actualites, setActualites] = useState([]);
	const wpRestUrl = endpoints.config.baseUrl+endpoints.categories['actualites'].wpRestUrl;

	useEffect(() => {
		fetch(wpRestUrl)
		.then((response) => {
    		if(!response.ok) throw new Error(response.status);
    		else return response.json();
  		})
  		.then((result) => {
		  	setIsLoading(false);
			setActualites(result);
  		})
  		.catch((error) => {
		  	setIsLoading(false);
			setError(error);
  		});

		return () => {
			setActualites([]);
			setIsLoading(true);
			setError(null);
		}    	
  	}, [wpRestUrl, setIsLoading])

	if (error) { // handle rest error
    	return (
			<div id="actualites" className="module">
				<div>Erreur : {error.message}</div>
			</div>
		);
  	} else { // display
		return (
			<div id="actualites" className="module">
				<div className="module-header">
					<h2 className="module-title">
						Actualités
					</h2>
				</div>
				<div className="module-content">
					<RBCarousel
						animation={true}
						autoplay={false}
						defaultActiveIndex={0}
						leftIcon={leftArrow}
						rightIcon={rightArrow}
						version={4}
					>
						{actualites.map((actualite, index) => 
							<div key={"actu-" + index} className="actualite-wrapper">
								<div className="actualite">									
									<Actualite actualite={actualite} />																			
								</div>
							</div>
						)}
					</RBCarousel>
				</div>
			</div>
		);
	  }
}

export default Actualites;