// src/modules/Presentation.js

import React, {useContext, useState, useEffect} from 'react';
import {LoaderContext} from '../contexts/LoaderContext';
import {EndpointsContext} from '../contexts/EndpointsContext';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Presentation = (props) => {
	// use LoaderContext, setter only
	const [, setIsLoading] = useContext(LoaderContext);
	// use EndpointsContect
	const endpoints = useContext(EndpointsContext);
	const [error, setError] = useState(false);
	const [presentation, setPresentation] = useState([]);
	const wpRestUrl = endpoints.config.baseUrl+endpoints.categories['presentation'].wpRestUrl;

	useEffect(() => {
		fetch(wpRestUrl)
		.then((response) => {
    		if(!response.ok) throw new Error(response.status);
    		else return response.json();
  		})
  		.then((result) => {
		  	setIsLoading(false);
			setPresentation(result);
  		})
  		.catch((error) => {
			setIsLoading(false);
			setError(error);
  		});

		return () => {
			setPresentation([]);
			setIsLoading(true);
			setError(null);
		}    	
  	}, [wpRestUrl, setIsLoading])

	if(error) {
    	return (
			<div id="presentation" className="module">
				<div>Erreur : {error.message}</div>
			</div>
		);
  	} else {
		return (
			<div id="presentation" className="module">
				<div className="module-header">
					<h2>
						Présentation
					</h2>
				</div>
				<div className="module-content">
					<PerfectScrollbar>
						<div className="presentation-content" dangerouslySetInnerHTML={{__html: presentation.texte}}></div>
					</PerfectScrollbar>
				</div>			
			</div>
		);
	  }
}

export default Presentation;