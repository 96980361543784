// src/modules/Contact.js

import React, {useContext, useState, useEffect, useRef} from 'react';
import JoditEditor from 'jodit-react';
import {FilePond} from 'react-filepond';
// import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import {LoaderContext} from '../contexts/LoaderContext';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';


const Contact = () => {
	// use Loadercontext, setter only
	const [, setIsLoading] = useContext(LoaderContext);
	const editor = useRef(null);
	const [email, setEmail] = useState('');	
    const [content, ] = useState('');
	const [mailSent, setMailSent] = useState(false);  
    const [mailError, setMailError] = useState(false);
    const config = {
		buttons: [
			'bold',
			'underline',
			'italic',
			'|',
			'ul',
			'ol',
			'|',
			'outdent',
			'indent',
			'|',
			'link',
			'|',
			'undo',
			'redo'
		]
    }

	useEffect(() => {
		setIsLoading(false);   	
  	}, [setIsLoading])

	const handleSubmit = (evt) => {
		evt.preventDefault();
		
		var formHtml = evt.target;
		var formData = new FormData(formHtml);
		var editor = document.querySelector('.jodit_wysiwyg');

		var data = {
			email: formData.get('email'),
			message: editor.innerHTML
		}

		fetch('/contact-handler/', {
            method: 'POST',
            body: JSON.stringify(data)
        })
		.then(response => response.json())
		.then(data => setMailSent(data.message))
		.catch(error => setMailError(error));
	}

	return (
		<main id="main" className="col-lg-9 col-md-9 single">
			<div className="container-fluid">
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<div id="section-header">
							<h2>Contact</h2>
							<hr />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
						
						{mailSent && <div className="succes">Votre message a été envoyé</div>}
						{!mailSent && mailError && <div className="erreur">Un problème est survenu lors de l'envoi de votre message. Merci de réessayer ultérieurement.</div>}
						
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="input-email">Email :</label>
								<input id="input-email" type="email" name="email" className="form-control" aria-describedby="email-help" placeholder="Saisir votre email" value={email} onChange={e => setEmail(e.target.value)} />
								<small id="email-help">Votre email ne sera <strong>jamais</strong> diffusé.</small>
							</div>
							<div className="form-group">
								<label htmlFor="input-message">Votre message :</label>
								
								<JoditEditor
									name="message"
            						ref={editor}
									value={content}
                					config={config}
        							tabIndex={1} // tabIndex of textarea

									/*
        							onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
									onChange={newContent => setContent(newContent)}
									*/
            					/>
								
							</div>
							<div className="form-group">
								<FilePond 
									allowMultiple={true} 
									maxFiles={3} 
									server={{
										url: '/upload-handler/',
										process: {
											url: 'index.php',
											method: 'POST',
											withCredentials: false,
											headers: {},
											timeout: 7000,
											onload: null,
											onError: function(err) {
												console.log("Failed because: " + err);
											}
										}
									}} 
								/>
							</div>
							<input type="submit" value="envoyer" />
						</form>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Contact;