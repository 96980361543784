// src/modules/common/MainTitle.js

import React from 'react';

const MainTitle = () => {
	return (
		<h1>Yann Chancerel<br />Sculpteur, graveur sur granit</h1>
	);
}

export default MainTitle;