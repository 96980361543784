// src/modules/Footer.js

import React from 'react';

const Footer = () => {
	return (
		<footer id="footer" className="col-lg-9 col-md-9">
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<div className="footer-content">
							<h3>Yann Chancerel, sculpteur - graveur sur granit &copy; 2020</h3>
							<p><strong>Décorations de jardin</strong> : fontaines et puits sculptés. <strong>Arts religieux et funéraires</strong> : plaques commémoratives, calvaires, statuaires, autels. <strong>Gravures</strong> : Numéros de maison, trophées</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer;