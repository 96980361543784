// src/modules/Carte.js

import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

class Carte extends React.Component {	

	constructor(props) {
		super(props);

		this.state = {
    		lat: 48.70007451339029,
    		lng: -3.0112516880035405,
    		zoom: 13,
  		}
	}
	
    render() {
		const position = [this.state.lat, this.state.lng]

        return  <div id="carte" className="module">
					<div className="module-header">
						<h2 className="module-title">
							Carte
						</h2>
					</div>
                    <div className="module-content">
						<Map center={[48.70007451339029, -3.0112516880035405]} zoom={12} onclick={this.handleClick}>
							<TileLayer
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							/>
							<Marker position={position}>
								<Popup>
									Yann Chancerel<br />2 chemin de Couraillon<br />22290 Pléhédel
								</Popup>
							</Marker>
						</Map>
					</div>
                </div>
    }
}

export default Carte;