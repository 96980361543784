// src/contexts/LoaderContext.js

import React, {useState} from 'react';

// LoaderContext object
// we’re passing in an array with two values: an empty object, and an empty function, as the initial value
const LoaderContext = React.createContext([false, () => {}]);

// LoaderProvider
const LoaderProvider = (props) => {
	// useState Hook to provide us state. 
	// We’re storing multiple values inside of this state, 
	// so we name the first variable state, and the second variable setState
	const [isLoading, setIsLoading] = useState({
		isLoading: false
	});

	return (
		// Finally, here we put the state object and the setter function into an array, 
		// and pass that into our Context Provider’s value
		<LoaderContext.Provider value={[isLoading, setIsLoading]}>
			{props.children}
		</LoaderContext.Provider>	
	);
}

// export context and provider
export {LoaderContext, LoaderProvider};
