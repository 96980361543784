// src/contexts/OverlayContext.js

import React, {useState} from 'react';

// overlayContext object
// we’re passing in an array with two values: an empty object, and an empty function, as the initial value
const OverlayContext = React.createContext([{}, () => {}]);

// overlayProvider
const OverlayProvider = (props) => {
	// useState Hook to provide us state. 
	// We’re storing multiple values inside of this state, 
	// so we name the first variable state, and the second variable setState
	const [open, setOpen] = useState({
		overlayOpen: false
	});

	return (
		// Finally, here we put the state object and the setter function into an array, 
		// and pass that into our Context Provider’s value
		<OverlayContext.Provider value={[open, setOpen]}>
			{props.children}
		</OverlayContext.Provider>	
	);
}

// export context and provider
export {OverlayContext, OverlayProvider};
