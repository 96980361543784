// src/modules/Player.js

import React from 'react';
import url from 'url';

const Player = (props) => {
	const urlObj = url.parse(props.source);
	const query = urlObj.query;
	const tabId = query.split('=');
	const id = tabId[1];
	const movieUrl = 'https://www.youtube.com/embed/'+id;

	return (
		<iframe width={props.largeur} height={props.hauteur} src={movieUrl} title={props.titre} frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
	);
}

export default Player;