// src/modules/Actualite.js

import React from 'react';
import Player from './Player';

const Actualite = (props) => {
	const {actualite} = props;
	let actuContentClassName, markupVideo, markupContent;

    /*
	if(actualite.hasPhoto) {
		markupPhoto =
			<div className="actualite-photo">
				<img src={actualite.photo.url} alt={actualite.titre} style={{maxHeight: '100%', width: 'auto'}} />
			</div>
		;
	}
    */

	if(actualite.hasVideo) {
		markupVideo = 
			<div className="actualite-video">
				<Player source={actualite.video} titre={actualite.titre} largeur="300" hauteur="100%" />
			</div>	
		;	
	}

	if(actualite.hasVideo) {
		actuContentClassName = "actualite-content actualite-content-with-before";	
	} else {
		actuContentClassName = "actualite-content actualite-content-alone";
	}

	markupContent = 
		<div className={actuContentClassName}>
			<h3>{actualite.titre}</h3>
			<div dangerouslySetInnerHTML={{ __html: actualite.contenu }} />
		</div>
	;

	return (
		<React.Fragment>
			{markupVideo}
			{markupContent}
		</React.Fragment>
	);
}

export default Actualite;