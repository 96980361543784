// src/modules/common/Logo.js

import React from 'react';

const Logo = () => {
	return(
		<svg id="logo" version="1.1" viewBox="0 0 87.667 87.667" xmlns="http://www.w3.org/2000/svg">
			<g transform="translate(-11.365 -10.853)" fill="none" strokeLinecap="round" strokeLinejoin="round">
				<path d="m76.962 40.104-10.829 0.0154-10.074 15.536-8.4473 13.598-14.179 0.0149m13.933-29.134 8.6925 15.521" stroke="#232323" strokeWidth="5"/>
				<circle cx="55.198" cy="54.687" r="42.333" stroke="#b9be96" strokeWidth="3"/>
			</g>
		</svg>
	);
}

export default Logo;