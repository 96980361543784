// src/modules/mobiles/ShowMobileMenu.js

import React, {useContext} from 'react';
import {OverlayContext} from '../../contexts/OverlayContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const ShowMobileMenu = () => {
	// use OverlayContext
	const [ctxOverlay, setCtxOverlay] = useContext(OverlayContext);

	const handleClick = (evt) => {
		setCtxOverlay(ctx => ({...ctxOverlay, overlayOpen: !ctxOverlay.overlayOpen, from:'./mobiles/',  whatToShow: 'MenuMobile'}));
	}

	return (
		<button id="show-mobile-menu" onClick={handleClick}>
			<FontAwesomeIcon icon={faBars} />
		</button>
	);
}

export default ShowMobileMenu;